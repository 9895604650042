export interface DocumentDataState {
    documents: Documents;
}

export const initialState: DocumentDataState = {
    documents: {},
};

export type Documents = Record<string, string | undefined>; //key: chatId_messageId_documentId, value: document base64 data

export interface DocumentData {
    chatId: string;
    messageId: string;
    documentId: string;
    documentData?: string;
}