import { makeStyles } from '@fluentui/react-components';
import React, { useEffect, useRef, useState } from 'react';

const useClasses = makeStyles({
    resizableWindow: {
        border: 'none',
        resize: 'both',
        minHeight: '30vh',
        maxHeight: '70vh',
        minWidth: '50%',
        maxWidth: '100%',
        width: '100%',
    },
});

interface HtmlIframeRendererProps {
    htmlText: string;
}

export const HtmlIframeRenderer: React.FC<HtmlIframeRendererProps> = ({ htmlText }) => {
    const classes = useClasses();
    const [iframeSrc, setIframeSrc] = useState('');
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [iframeHeight, setIframeHeight] = useState('50vh');

    useEffect(() => {
        // Create a Blob from the string content
        const blob = new Blob([htmlText], { type: 'text/html' });
        
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
        setIframeSrc(url);

        // Clean up the URL object when the component unmounts
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [htmlText]);

    const handleLoad = () => {
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument ?? iframeRef.current.contentWindow?.document;
            const body = iframeDocument?.body;

            // Set iframe height based on the content height
            if (body) {
                setIframeHeight(`${body.scrollHeight}px`);
            }
        }
    }

    return (
        <iframe
            ref={iframeRef}
            src={iframeSrc}
            onLoad={handleLoad}
            className={classes.resizableWindow}
            style={{ height: iframeHeight }}
        />
    );
};