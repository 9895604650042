import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentData, DocumentDataState, initialState } from './DocumentDataState';

export const documentDataSlice = createSlice({
    name: 'documentData',
    initialState,
    reducers: {
        storeDocumentData: (state: DocumentDataState, action: PayloadAction<DocumentData>) => {
            state.documents[`${action.payload.chatId}_${action.payload.messageId}_${action.payload.documentId}`] = action.payload.documentData;
        },
        removeDocumentData: (state: DocumentDataState, action: PayloadAction<DocumentData>) => {
            state.documents[`${action.payload.chatId}_${action.payload.messageId}_${action.payload.documentId}`] = undefined;
        },
    },
});

export const { storeDocumentData, removeDocumentData } = documentDataSlice.actions;

export default documentDataSlice.reducer;