import { IUserPhoto } from '../models/UserPhoto';
import { BaseService } from './BaseService';

export class UserService extends BaseService {
    public getChatPhotoAsync = async (
        accessToken: string,
        userIds: string[],
        botImageBase64?: string,
        personaIconSvg?: string,
        personaIconBackgroundColor?: string,
        personaIconColor?: string,
    ): Promise<IUserPhoto> => {
        const body = {
            userIds,
            personaIconBase64: botImageBase64,
            personaIconSvg,
            personaIconBackgroundColor,
            personaIconColor,
        };

        const result = await this.getResponseAsync<IUserPhoto>(
            {
                commandPath: 'users/photo',
                method: 'POST',
                body,

            },
            accessToken,
        );
        return result;
    };
}
